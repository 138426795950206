// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { WORKSPACES_WEB, CONFIG_ELEMENTS } from "../constants";
import { ErmineLogger } from "./ermineLogger";

let logger: ErmineLogger;

export const initalizeLogger = (): ErmineLogger => {
  const environmentConfigElement = document.getElementById(
    CONFIG_ELEMENTS.ENV_INFO
  );
  const environmentConfig = environmentConfigElement
    ? JSON.parse(environmentConfigElement.innerHTML)
    : {};
  const {
    stage = WORKSPACES_WEB.STAGE,
    loggerEndpoint = null,
  } = environmentConfig;

  logger = new ErmineLogger({
    url: loggerEndpoint,
    logToConsole: stage !== "prod",
  });

  logger.addErrorListener();

  return logger;
};

export default logger || initalizeLogger();
export * from "./metricName";
