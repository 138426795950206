import { useEffect } from "react";
import { useSessionDataStore } from "./useSessionDataStore";

export const useMicrophoneTimer = (microphoneEnabled: boolean): void => {
  const {
    addMicrophoneEnabledDuration,
    microphoneLastEnabledTime,
    setMicrophoneLastEnabledTime,
    sessionStartTime,
  } = useSessionDataStore();

  useEffect(() => {
    if (sessionStartTime !== undefined) {
      if (microphoneEnabled) {
        const microphoneEnabledTimestamp = performance.now();
        setMicrophoneLastEnabledTime(microphoneEnabledTimestamp);
      } else {
        const microphoneDisabledTimestamp = performance.now();
        if (microphoneLastEnabledTime !== undefined) {
          addMicrophoneEnabledDuration(
            microphoneDisabledTimestamp - microphoneLastEnabledTime
          );
        }
      }
    }
  }, [microphoneEnabled, sessionStartTime]);
};
