import log from "../logging";

export const DEFAULT_LANGUAGE = "en-US";
export const DEFAULT_TIME_ZONE = "Etc/UTC";

export function getBrowserLanguage(): string {
  let language: string = DEFAULT_LANGUAGE;
  const WSW_LANGUAGE_COOKIE_NAME = "wsw-language";
  const userSelectedLanguage = document.cookie
    .match(`(^|;)\\s*${WSW_LANGUAGE_COOKIE_NAME}\\s*=\\s*([^;]+)`)
    ?.pop();
  try {
    language =
      userSelectedLanguage ??
      window.navigator.languages?.[0] ??
      window.navigator.language ?? // For Firefox, Opera, Google Chrome and Safari
      // @ts-expect-error: browserLanguage is only for older version of IE (not exist type error)
      window.navigator.browserLanguage ??
      // @ts-expect-error: userLanguage is only for IE (not exist type error)
      window.navigator.userLanguage ??
      DEFAULT_LANGUAGE;
  } catch (e) {
    log.warn("Cannot read language setting from browser", e);
  }

  // To avoid API call failed, follow Ermine CP model restriction
  if (!/^[a-zA-Z0-9_-]+$/.test(language)) {
    return DEFAULT_LANGUAGE;
  }
  // Most of the language codes should be within the range
  if (language.length < 2) {
    return DEFAULT_LANGUAGE;
  }
  if (language.length > 32) {
    return DEFAULT_LANGUAGE;
  }
  return language;
}

export function getBrowserTimeZone(): string {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
  let timeZone: string = DEFAULT_TIME_ZONE;
  try {
    timeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone ?? DEFAULT_TIME_ZONE;
  } catch (e) {
    log.warn("Cannot read time zone setting from browser", e);
  }

  // To avoid API call failed, follow Ermine CP model restriction
  if (!/^[a-zA-Z_\-/]+$/.test(timeZone)) {
    return DEFAULT_TIME_ZONE;
  }
  // Most of the time zone codes should be within the range.
  if (timeZone.length < 5) {
    return DEFAULT_TIME_ZONE;
  }
  if (timeZone.length > 64) {
    return DEFAULT_TIME_ZONE;
  }
  return timeZone;
}
