import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
  TextContent,
} from "@cloudscape-design/components";
import "./ErrorModal.css";
import { Trans, useTranslation } from "react-i18next";
import { getDeviceBrowser } from "../../utils/userAgentUtils";
import { sessionStateCookieManager } from "../../utils/SessionStateCookieManager";
import {
  CookieSyncAdminGuideUrl,
  CookieSyncExtensionLinks,
} from "../../constants/CookieSync";
import { setHideExtensionModalCookieState } from "../../utils/bannerUtils";
import { CookieSyncSupportedBrowserType } from "../../types/cookies";
import { getRebrandedMessageIdIfExists } from "../../i18n";

interface ExtensionModalProps {
  dismissExtensionModal?: () => void;
  onRedirectToLogin: (redirectToLogin: boolean) => void;
}

export const ExtensionModal = ({
  dismissExtensionModal,
  onRedirectToLogin,
}: ExtensionModalProps): JSX.Element => {
  const browserType = getDeviceBrowser() as CookieSyncSupportedBrowserType;
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  return extensionInstalled ? (
    <AfterInstallExtensionModal
      dismissExtensionModal={dismissExtensionModal}
      browserType={browserType}
    />
  ) : (
    <BeforeInstallExtensionModal
      setExtensionInstalled={setExtensionInstalled}
      dismissExtensionModal={dismissExtensionModal}
      onRedirectToLogin={onRedirectToLogin}
      browserType={browserType}
    />
  );
};

export const getExtensionLink = (
  browserType: CookieSyncSupportedBrowserType
): string => {
  switch (browserType) {
    case "Chrome":
      return CookieSyncExtensionLinks.Chrome;
    case "Firefox":
      return CookieSyncExtensionLinks.Firefox;
  }
};

const getWebStoreString = (
  browserType: CookieSyncSupportedBrowserType
): JSX.Element => {
  switch (browserType) {
    case "Chrome":
      return (
        <Trans
          i18nKey={getRebrandedMessageIdIfExists(
            "presession.extensionModal.content.step1.Chrome"
          )}
        />
      );
    case "Firefox":
      return (
        <Trans
          i18nKey={getRebrandedMessageIdIfExists(
            "presession.extensionModal.content.step1.Firefox"
          )}
        />
      );
  }
};

export const LearnMoreLink = (): JSX.Element => (
  <Link href={CookieSyncAdminGuideUrl} target="_blank">
    <Trans i18nKey={"presession.extensionModal.content.learnMore"} />
  </Link>
);

interface MainTextContentProps {
  browserType: CookieSyncSupportedBrowserType;
}

const MainTextContent = ({ browserType }: MainTextContentProps) => {
  return (
    <TextContent>
      <p>
        <Trans
          i18nKey={getRebrandedMessageIdIfExists(
            "presession.extensionModal.content.line1"
          )}
        >
          {[<LearnMoreLink />]}
        </Trans>
      </p>
      <p>
        <Trans
          i18nKey={"presession.extensionModal.content.line2"}
          browser={browserType}
        />
      </p>
      <ol>
        <li>{getWebStoreString(browserType)}</li>
        <li>
          <Trans i18nKey={"presession.extensionModal.content.step2"} />
        </li>
      </ol>
    </TextContent>
  );
};

interface BeforeInstallExtensionModalProps {
  dismissExtensionModal?: () => void;
  browserType: CookieSyncSupportedBrowserType;
  onRedirectToLogin: (redirectToLogin: boolean) => void;
  setExtensionInstalled?: (extensionInstalled: boolean) => void;
}

const BeforeInstallExtensionModal = ({
  dismissExtensionModal,
  browserType,
  onRedirectToLogin,
  setExtensionInstalled,
}: BeforeInstallExtensionModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [hideModalChecked, setHideModalChecked] = useState(false);
  return (
    <Modal
      onDismiss={() => {
        onRedirectToLogin(true);
      }}
      visible={true}
      data-testid="extension-modal-not-installed"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xxxs">
            <Button
              variant="link"
              onClick={() => {
                dismissExtensionModal();
                if (hideModalChecked) {
                  setHideExtensionModalCookieState(true); // set cookie to hide modal permanently in the same browser
                }
              }}
            >
              <Trans i18nKey={"presession.extensionModal.button.skip"} />
            </Button>
            <Button
              variant="primary"
              iconName="external"
              iconAlign="right"
              onClick={() => {
                if (browserType === "Firefox") {
                  window.open(getExtensionLink(browserType), "_self");
                } else {
                  window.open(getExtensionLink(browserType), "_blank");
                }
                setExtensionInstalled(true);
              }}
            >
              <Trans
                i18nKey={getRebrandedMessageIdIfExists(
                  "presession.extensionModal.button.download"
                )}
              />
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t("presession.extensionModal.title")}
    >
      <SpaceBetween direction="vertical" size="m">
        <MainTextContent browserType={browserType} />
        <Checkbox
          onChange={({ detail }) => setHideModalChecked(detail.checked)}
          checked={hideModalChecked}
        >
          <Trans i18nKey={"presession.extensionModal.checkbox"} />
        </Checkbox>
      </SpaceBetween>
    </Modal>
  );
};

interface AfterInstallExtensionModalProps {
  dismissExtensionModal?: () => void;
  browserType: CookieSyncSupportedBrowserType;
}

const AfterInstallExtensionModal = ({
  dismissExtensionModal,
  browserType,
}: AfterInstallExtensionModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal
      onDismiss={() => {
        sessionStateCookieManager.setHideExtensionModalSessionState(true); // set session storage to hide modal in current tab due to reloading
        window.location.reload(); // refresh documentElement to load extension in current tab if installed
      }}
      visible={true}
      data-testid="extension-modal-installed"
      footer={
        <Box float="right">
          <Button
            variant="primary"
            onClick={() => {
              sessionStateCookieManager.setHideExtensionModalSessionState(true); // set session storage to hide modal in current tab due to reloading
              window.location.reload(); // refresh documentElement to load extension in current tab if installed
            }}
          >
            <Trans i18nKey={"presession.extensionModal.button.startSession"} />
          </Button>
        </Box>
      }
      header={t("presession.extensionModal.title")}
    >
      <SpaceBetween direction="vertical" size="m">
        <MainTextContent browserType={browserType} />
        <StatusIndicator>
          <Trans
            i18nKey={getRebrandedMessageIdIfExists(
              "presession.extensionModal.successMessage"
            )}
          />
        </StatusIndicator>
      </SpaceBetween>
    </Modal>
  );
};
