import {
  ColumnLayout,
  FormField,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import React from "react";
import { AppStreamSdkProps } from "../../../../types/Toolbar";
import { useToolbarPreferenceStore } from "../../../../hooks/useToolbarPreferenceStore";
import { Trans, useTranslation } from "react-i18next";

const MicrophoneSelector = (): JSX.Element => {
  const { t } = useTranslation();

  // TODO: Replace values with actual device options once we have access to the SDK functions
  const dummyOptions = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
  ];
  const [selectedOption, setSelectedOption] = React.useState({
    label: dummyOptions[0].label,
    value: dummyOptions[0].value,
  });

  return (
    <FormField
      label={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.label"
      )}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.description"
      )}
      data-testid="microphoneFormField"
    >
      <Select
        selectedOption={selectedOption}
        onChange={({ detail }) =>
          setSelectedOption({
            label: detail.selectedOption.label,
            value: detail.selectedOption.value,
          })
        }
        options={dummyOptions}
        data-testid="microphoneSelectMenu"
      />
    </FormField>
  );
};

const CameraSelector = (): JSX.Element => {
  const { t } = useTranslation();

  // TODO: Replace values with actual device options once we have access to the SDK functions
  const dummyOptions = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
  ];
  const [selectedOption, setSelectedOption] = React.useState({
    label: dummyOptions[0].label,
    value: dummyOptions[0].value,
  });

  return (
    <FormField
      label={t("toolbar.item.preference.content.tab.audioVideo.camera.label")}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.camera.description"
      )}
      data-testid="cameraFormField"
    >
      <Select
        selectedOption={selectedOption}
        onChange={({ detail }) =>
          setSelectedOption({
            label: detail.selectedOption.label,
            value: detail.selectedOption.value,
          })
        }
        options={dummyOptions}
        data-testid="cameraSelectMenu"
      />
    </FormField>
  );
};

/**
 * Preferences - Audio & Video tab content
 */
export const AudioVideoTabContent = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  return (
    <ColumnLayout columns={2}>
      <SpaceBetween direction="vertical" size="m">
        <MicrophoneSelector />
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="m">
        <CameraSelector />
      </SpaceBetween>
    </ColumnLayout>
  );
};
