// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import React, { useEffect, useState } from "react";
import { ICredentials } from "@aws-amplify/core";
import { getAuthType, isFeatureEnabled } from "../../../../configurations";
import log, { METRIC_NAME } from "../../../../logging";
import {
  getCookiesWithTimeout,
  isCookieSynchronizationExtensionInstalled,
} from "../../../../cookies/CookieSynchronization";
import OidcCookieSyncConfigFetcher from "../OidcCookieSyncConfigFetcher";
import Sigv4CookieSyncConfigFetcher from "../Sigv4CookieSyncConfigFetcher";
import browser from "webextension-polyfill";
import { AuthType } from "../../../../enums";
import { AWSError } from "aws-sdk";
import { CookieResult } from "../../../../types/cookies";

interface Props {
  onCookiesResult: (cookieResult: CookieResult) => void;
  sigv4Credentials?: ICredentials;
}

const CookieManager = (props: Props): JSX.Element | null => {
  const { onCookiesResult, sigv4Credentials } = props;

  const authType = getAuthType();
  const isUnificationEnabled = isFeatureEnabled("unification");
  const isCookieExtensionInstalled = isCookieSynchronizationExtensionInstalled();

  const [cookieSyncConfig, setCookieSyncConfig] = useState<{
    allowlistFilter: browser.Cookies.GetAllDetailsType[];
    blocklistFilter: browser.Cookies.GetAllDetailsType[];
    state: "ENABLED" | "DISABLED" | "LOADING";
  }>({
    allowlistFilter: null,
    blocklistFilter: null,
    state: "LOADING",
  });

  const onCookieSyncConfigFetch = (
    allowlistFilter: browser.Cookies.GetAllDetailsType[],
    blocklistFilter: browser.Cookies.GetAllDetailsType[],
    metricName: METRIC_NAME
  ) => {
    log.publishHttpStatusMetric(metricName, 200);
    setCookieSyncConfig({
      allowlistFilter,
      blocklistFilter,
      state: allowlistFilter?.length ? "ENABLED" : "DISABLED",
    });
  };

  const onCookieSyncConfigFetchError = (
    err: AWSError,
    metricName: METRIC_NAME
  ) => {
    const { statusCode } = err;
    log.publishHttpStatusMetric(metricName, statusCode);
    onCookiesResult({
      cookies: [],
      error: "CONFIG_FETCH_ERROR",
    });
  };

  useEffect(() => {
    if (cookieSyncConfig?.state === "ENABLED" && !isCookieExtensionInstalled) {
      log.publishCounterMetric(METRIC_NAME.MISSING_COOKIE_SYNC_EXTENSION);
      onCookiesResult({
        cookies: [],
        error: "EXTENSION_NOT_INSTALLED",
      });
    } else if (
      cookieSyncConfig?.state === "ENABLED" &&
      isCookieExtensionInstalled
    ) {
      getCookiesWithTimeout(
        cookieSyncConfig.allowlistFilter,
        cookieSyncConfig.blocklistFilter,
        2000 // Wait up to 2 seconds for response from extension
      ).then((result) => {
        switch (result.type) {
          case "cookies":
            onCookiesResult({
              cookies: result.cookies,
              error: null,
            });
            break;
          case "error":
            log.logMessage(
              `Error occurred when communicating with extension: ${result.error}`
            );
            onCookiesResult({
              cookies: [],
              error: "EXTENSION_REQUEST_ERROR",
            });
            break;
        }
      });
    } else if (cookieSyncConfig?.state === "DISABLED") {
      onCookiesResult({
        cookies: [],
        error: null,
      });
    }
  }, [cookieSyncConfig, isCookieExtensionInstalled, onCookiesResult]);

  if (cookieSyncConfig.state === "LOADING") {
    switch (authType) {
      case AuthType.STANDARD: {
        return isUnificationEnabled ? (
          <OidcCookieSyncConfigFetcher
            onCookieSyncConfigFetch={onCookieSyncConfigFetch}
            onCookieSyncConfigFetchError={onCookieSyncConfigFetchError}
          />
        ) : (
          <Sigv4CookieSyncConfigFetcher
            onCookieSyncConfigFetch={onCookieSyncConfigFetch}
            onCookieSyncConfigFetchError={onCookieSyncConfigFetchError}
            sigv4Credentials={sigv4Credentials!}
          />
        );
      }
      case AuthType.IAM_IDENTITY_CENTER: {
        return (
          <OidcCookieSyncConfigFetcher
            onCookieSyncConfigFetch={onCookieSyncConfigFetch}
            onCookieSyncConfigFetchError={onCookieSyncConfigFetchError}
          />
        );
      }
      default: {
        log.error("Invalid authentication type");
        throw Error("Invalid authentication type");
      }
    }
  }

  return null;
};

export default CookieManager;
