// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import KatalLogger, { LoggerConfig } from "@amzn/katal-logger";
import { METRIC_NAME, TOOLBAR_METRIC_NAME } from "./metricName";

type ErmineConfig = LoggerConfig;

export class ErmineLogger extends KatalLogger {
  constructor(config?: ErmineConfig) {
    super({ ...config });
  }

  public logMessage(message: string, context?: Record<string, unknown>): void {
    context = context || {};
    context["userSub"] = window.userSub;
    this.info(message, context);
  }

  private logMetric(
    metricName: string,
    value: number | string,
    context?: Record<string, unknown>
  ): void {
    context = context || {};
    context[metricName] = value;
    this.logMessage(metricName, context);
  }

  public publishCounterMetric(
    metricName: METRIC_NAME | TOOLBAR_METRIC_NAME,
    count = 1,
    context?: Record<string, unknown>
  ): void {
    this.logMetric(metricName, count, context);
  }

  public publishMetricWithDimension(
    metricName: METRIC_NAME | TOOLBAR_METRIC_NAME,
    dimensionValue: string,
    context?: Record<string, unknown>
  ): void {
    this.logMetric(metricName, dimensionValue, context);
  }

  public publishNumericMetric(
    metricName: METRIC_NAME | TOOLBAR_METRIC_NAME,
    value: number,
    context?: Record<string, unknown>
  ): void {
    this.logMetric(metricName, value, context);
  }

  public publishRatioMetric(
    metricName: METRIC_NAME | TOOLBAR_METRIC_NAME,
    ratio: number,
    context?: Record<string, unknown>
  ): void {
    this.logMetric(metricName, ratio, context);
  }

  public publishHttpStatusMetric(
    metricName: METRIC_NAME,
    httpStatus: number,
    context?: Record<string, unknown>
  ): void {
    this.logMetric(metricName, httpStatus, context);
  }
}
